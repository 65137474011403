import {Injectable, signal} from '@angular/core'

export const HELP_SUBJECTS = [
    'Public data',
    'User abilities',
    'Plots',
    'Diver',
    'Object select',
] as const

export type HelpSubject = null | typeof HELP_SUBJECTS[number]

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    private readonly _openState = signal(false)
    private readonly _subjectRequest = signal<HelpSubject>(null)

    readonly state = this._openState.asReadonly()
    readonly subjectRequest = this._subjectRequest.asReadonly()


    constructor() {
    }

    open(request: HelpSubject = null) {
        this._openState.set(true)
        this._subjectRequest.set(request)
    }

    close() {
        this._openState.set(false)
    }

    toggle() {
        this._openState.set(!this._openState())
    }
}
